import { dataAtom, dataComposer } from "@frend-digital/centra";

import { baseAtomics } from "@/lib/centra/atomicSetup";
import type { CentraProduct } from "../../atomicSetup";
import {
  getAvailability,
  getComingSoon,
  getLabels,
  getSoldOut,
  getVariants
} from "../../localAtomics";
import getProductCore from "./getCore";
import getProductDescription from "./getDescription";
import getProductGallery from "./getGallery";

const { getProduct, getSku, getProductBreadcrumbs } = baseAtomics;

const formatProductCard = (product: CentraProduct) => {
  const core = getProductCore(product);
  const id = dataAtom(getProduct, 2)(product);
  const gallery = getProductGallery(product);
  const description = getProductDescription(product);
  const comingSoon = dataAtom(getComingSoon, 2)(product);
  const soldOut = dataAtom(getSoldOut, 2)(product);
  const sku = dataAtom(getSku, 2)(product);
  const breadcrumbs = dataAtom(getProductBreadcrumbs, 2)(product);
  const labels = dataAtom(getLabels, 2)(product);
  const variants = dataAtom(getVariants, 2)(product);
  const availability = dataAtom(getAvailability, 2)(product);

  const productCard = dataComposer({
    core,
    gallery,
    soldOut,
    comingSoon,
    description,
    /**
     * The display item ID
     */
    id,
    breadcrumbs,
    sku,
    labels,
    variants,
    availability
  });
  return productCard.data;
};

// export const getProductCardData = (product: CentraProduct) =>
//   formatProductCard(product)["data"];

export default formatProductCard;

export type ProductCard = ReturnType<typeof formatProductCard>;

const getCategoryId = async (
  categoryArray: string[] | undefined,
  customerToken?: string
): Promise<string | null | undefined> => {
  const apiUrl = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;

  if (!categoryArray || categoryArray.length === 0) {
    return undefined;
  }
  const categoryUri = categoryArray.join("/");
  try {
    const categories = await fetch(`${apiUrl}/categories`, {
      headers: {
        "API-token": customerToken || "",
        "Content-Type": "application/json"
      },
      method: "GET"
    });

    if (!categories.ok) throw new Error("Error fetching categories");
    const categoriesRes = await categories.json();

    const id = categoriesRes?.categories?.find(
      (category: any) =>
        category.uri.toLowerCase() === categoryUri.toLowerCase()
    )?.category;
    return id;
  } catch (e: any) {
    console.error(e.message);
    return;
  }
};

export default getCategoryId;

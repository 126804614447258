import { useQueryState } from "@/hooks/useQueryState";
import formatProductCard from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import { getProducts } from "@/lib/centra/hooks/useProductFilter/getProducts";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

export const SEARCH_QUERY_KEY = "q";

export const useSearchQuery = (initialValue: string) => {
  return useQueryState(SEARCH_QUERY_KEY, initialValue);
};

export const getSearchUrl = (query: string) => {
  const searchParams = new URLSearchParams();
  if (query) searchParams.set(SEARCH_QUERY_KEY, query);

  return `/search?${searchParams.toString()}`;
};

export const useSearchUrl = (query: string) => {
  return useMemo(() => getSearchUrl(query), [query]);
};

export const useProductSearch = (
  query: string,
  { enabled = true, productsPerPage = 4 } = {}
) => {
  return useQuery({
    queryKey: ["search-modal", query],
    queryFn: async ({ signal }) => {
      const products = await getProducts({
        category: [],
        search: query,
        cardFormatter: formatProductCard,
        signal,
        productsPerPage
      });
      return products;
    },
    enabled,
    placeholderData: keepPreviousData
  });
};

import type {
  Entries,
  ErrorLevel,
  ErrorLevelError,
  ErrorObject,
  FormattedObject,
  FormattingError,
  ObjectToFormat,
  PreformattedObject,
} from "../../types/utility";

type DataReturn<E extends ErrorLevel, R> = E extends ErrorLevelError ? R | undefined : R
const isDataReturn = <E extends ErrorLevel, R>(defaultReturn: R | undefined, errorLevel: E): 
  defaultReturn is DataReturn<E, R> => {
    if (errorLevel >= 2) return true
    return (defaultReturn !== undefined && defaultReturn !== null)
  }

export const dataAtom =
  <D, R extends {} | null | undefined, E extends ErrorLevel>(
    atomicFunction: (arg0: D) => R, 
    errorLevel: E,
    defaultReturn: R | undefined = undefined,
  ): (arg0: D) => {data: DataReturn<E, R>, error: FormattingError | undefined, errorLevel: ErrorLevel} => 
    (data) => {
      if (!isDataReturn(defaultReturn, errorLevel)) throw new Error("")
      try {
        return {
          data: atomicFunction(data),
          error: undefined,
          errorLevel: 0
      };
    } catch (e: any) {
      const obj = {
        data: defaultReturn,
        error: {
          level: errorLevel,
          message: e.message,
        },
        errorLevel: errorLevel
      };
      
      return obj;
    }
  }

export const atomizeData = <T>(data: T): { data: T, error: undefined, errorLevel: 0 } => 
  ({ data, error: undefined, errorLevel: 0 });

const typedEntries = <T extends PreformattedObject>(obj: ObjectToFormat<T>) => {
  return Object.entries(obj) as unknown as Entries<T>
}

type EmptyObject<T> = {[P in keyof T]: {data: undefined}}

const isFormattingError = (error: ErrorObject): error is FormattingError => typeof error.level === "string"

const maxErrorLevel = (level1: ErrorLevel, level2: ErrorLevel): ErrorLevel => level2 > level1 ? level2 : level1;

export const dataComposer = <T extends PreformattedObject>(
  obj: ObjectToFormat<T>
): FormattedObject<T> => {
  const entries = typedEntries(obj)
  const fatalError = entries.reduce<boolean>((prev: boolean, curr) => {
    if (prev) return prev;
    return (curr[1].error?.level === 3)
  }, false)

  const emptyObject = entries.reduce<Partial<EmptyObject<T>>>((prev, curr) => ({...prev, [curr[0]]: undefined }), {}) as unknown as EmptyObject<T>

  const formattedEntries = entries.reduce<FormattedObject<T>>(
    (prev: FormattedObject<T>, curr): FormattedObject<T> => {
      const key = curr[0];
      const data = fatalError ? undefined : curr[1].data;
      const error = curr[1].error;
      const errorLevel = curr[1].errorLevel;

      if (error && prev.error){ 
        prev.error[key] 
        prev.errorLevel = isFormattingError(error) ? error.level : errorLevel;
      }
      if (error && !prev.error) {
        prev.error = ({[key]: error} as {[P in keyof T]: ErrorObject})
        prev.errorLevel = isFormattingError(error) 
          ? maxErrorLevel(prev.errorLevel, error.level) 
          : maxErrorLevel(prev.errorLevel, errorLevel)
      }
      if (error && typeof error.level === "string" && error.level > 2) return prev;

      if (prev.data) {
        prev.data[key] = data;
      }
      return prev;
    },
    { data: emptyObject, error: undefined, errorLevel: 0 }
  );

  if (formattedEntries.errorLevel === 3) formattedEntries.data = undefined

  return formattedEntries;
};
"use client";

import clsx from "clsx";
import type { ComponentProps } from "react";
import { forwardRef, useId, useImperativeHandle, useRef } from "react";

import { useTranslations } from "next-intl";
import { useFormContext } from "react-hook-form";
import styles from "./index.module.css";

interface InputProps extends React.ComponentProps<"input"> {
  label?: string;
  icon?: React.ReactNode;
  error?: string | boolean;

  variant?: "default" | "search";

  onClear?: () => void;
}
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      id: initialId,
      children,
      icon,
      type,
      variant = "default",
      onClear,
      className,
      error,
      ...props
    },
    externalRef
  ) => {
    const internalId = useId();
    const id = initialId ?? internalId;
    const isSearch = variant === "search";
    const t = useTranslations();
    /**
     * Expose ref to react hook form
     */
    const ref = useRef<HTMLInputElement>(null!);
    useImperativeHandle(externalRef, () => ref.current);

    /**
     * Clear the input field imperatively (react hook form)
     */
    const handleClear = () => {
      if (ref.current && !props.value) {
        ref.current.value = "";
        ref.current.focus();
      }

      onClear?.();
    };

    return (
      <label
        className={clsx(styles.label, className, error && styles.error)}
        htmlFor={id}
      >
        {label && (
          <span className={clsx(styles.labelText)}>
            {typeof error === "string" ? error : label}
          </span>
        )}
        <div className={clsx(styles.input, isSearch && styles.search)}>
          <input
            ref={ref}
            type={type ?? (isSearch ? "search" : undefined)}
            id={id}
            {...props}
          />
          {isSearch ? (
            <>
              <InputAction className={styles.clearIcon} onClick={handleClear}>
                <p>{t("search.clearSearch")}</p>
              </InputAction>
            </>
          ) : (
            icon
          )}
        </div>
      </label>
    );
  }
);

Input.displayName = "Input";

export const InputAction = ({
  children,
  className,
  ...props
}: React.ComponentProps<"button">) => {
  return (
    <button
      type="button"
      className={clsx(styles.inputAction, className)}
      {...props}
    >
      {children}
    </button>
  );
};

/**
 * React Hook Form powered InputField
 */
export const InputField = ({
  name,
  ...props
}: ComponentProps<typeof Input> & { name: string }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  return (
    <Input
      {...register(name)}
      error={errors[name]?.message as string}
      {...props}
    />
  );
};

import type { CentraSelectionItem } from "@frend-digital/centra/schemas/selection/selectionProductSchema";

import { getMediaObjects } from "./atomics/media";
import type { CentraProduct } from "./atomicSetup";
import { baseAtomics, VariantAvailability } from "./atomicSetup";
import formatProductCard from "./formatters/formatProductCards/formatProductCard";
import { formatVariant } from "./formatters/formatVariants";
import {
  getSelectionItemLine,
  getSelectionItemName,
  getSelectionItemPriceEach,
  getSelectionItemQuantity,
  getSelectionItemSize,
  getSelectionItemUri,
  getSelectionItemVariantName,
} from "./selectionAtoms";

const {
  getMediaBySize,
  getMeasurementChart,
  getProductBreadcrumbs,
  getProductSizes,
} = baseAtomics;

const formatMeasurementChart = (chart: {
  contents: { x: number; y: number; content: string }[];
  x: string[];
  y: string[];
}) => {
  const { contents, x, y } = chart;
  const formattedChart = [];

  for (let i = 0; i < y.length; i++) {
    const row = { size: y[i] } as {
      size: string;
      [measurement: string]: string;
    };
    for (let j = 0; j < x.length; j++) {
      const measurement = x[j];
      const value = contents.find(
        (content: { x: number; y: number }) =>
          content.x === j && content.y === i,
      )?.content;
      if (value) {
        row[measurement] = value;
      }
    }
    formattedChart.push(row);
  }

  return { chart: formattedChart, keys: x };
};

export const getSizeGuide = (product: CentraProduct) => {
  const measurementChart = getMeasurementChart(product);
  const sizeGuide = formatMeasurementChart(measurementChart);
  return sizeGuide;
};

export const getComingSoon = (product: CentraProduct) => {
  const availability = getAvailability(product);

  return availability === VariantAvailability.ComingSoon;
};

export const getSoldOut = (product: CentraProduct) => {
  const sizes = getProductSizes(product);
  const soldOut = sizes?.every((size) => size.stock === false);

  return soldOut;
};

export const getSwatch = (product: CentraProduct) => {
  const swatch = product.sh_swatch;
  return swatch;
};

export const getProductFit = (product: CentraProduct) => {
  const fit = product.pr_fit || "regular";
  return fit;
};

export const getProductType = (product: CentraProduct): string | undefined => {
  const type = product.pr_type;
  return type;
};

export const getCare = (product: CentraProduct) => {
  const care = product.pr_care;
  return care;
};

export const getLabels = (product: CentraProduct) => {
  const labels: string[] = product.var_label ?? [];

  return labels;
};

export const getAvailability = (product: CentraProduct) => {
  const availability =
    product.var_availability || VariantAvailability.Available;

  return availability;
};

export const getModelDisclaimer = (product: CentraProduct) => {
  return product.dis_model_disclaimer_value;
};

export const getFitAdvice = (product: CentraProduct) => {
  const fit = product.pr_fit_advice;
  return fit;
};

export const getFaq = (product: CentraProduct) => {
  const faq = product.pr_faq;
  return faq;
};

export const getShortDesc = (product: CentraProduct) => {
  const desc = product.excerptHtml;
  return desc;
};

export const getFabricProps = (product: CentraProduct) => {
  const fabricProps = product.pr_fabric_props;
  return fabricProps;
};

export const getMedia = (product: CentraProduct) => {
  const objects = getMediaObjects(product, "full");

  return objects;
};

const getRelatedProducts = (product: CentraProduct) => {
  return product.relatedProducts;
};
export const getSpecs = (product: CentraProduct) => {
  return {
    name: product.name,
    sku: product.sku,
    collection: product.collection,
    variant: product.variantName,
  };
};

export const getRelation = (
  product: CentraProduct,
  relation: string,
  includeSelf: boolean,
) => {
  const relatedProducts = getRelatedProducts(product) as CentraProduct[];
  if (Array.isArray(relatedProducts)) {
    const inactiveVariants = relatedProducts
      ?.filter((product) => product.relation === relation)
      .map((product) => {
        return {
          ...formatVariant(product).data,
          selected: false,
        };
      });
    let variants;
    if (includeSelf) {
      const activeVariant = { ...formatVariant(product).data, selected: true };
      variants = inactiveVariants
        ? [activeVariant, ...inactiveVariants]
        : [activeVariant];
    } else {
      variants = inactiveVariants;
    }

    return variants.sort((a, b) => {
      const aSoldOut = a.soldOut ?? false;
      const bSoldOut = b.soldOut ?? false;

      if (aSoldOut === bSoldOut) return 0;
      return aSoldOut ? 1 : -1;
    });
  }
  return [];
};

export const getVariants = (product: CentraProduct) => {
  return getRelation(product, "variant", true);
};
export type ProductVariant = ReturnType<typeof getVariants>[number];

export const getGender = (product: CentraProduct) => {
  return product.dis_gender;
};

export const getGenders = (product: CentraProduct) => {
  return getRelation(product, "gender", false);
};

export const getRelatedProductCards = (product: CentraProduct) => {
  const data = getRelatedProducts(product) as CentraProduct[];
  if (!Array.isArray(data)) return [];
  const relatedProducts = data?.filter(
    (product) => product.relation !== "variant",
  );
  return relatedProducts.map((product) => formatProductCard(product));
};
export type RelatedProductCards = ReturnType<typeof getRelatedProductCards>;

export const getFirstImage = (
  item: CentraSelectionItem,
): {
  standard: {
    href: string;
    alt: string;
  };
  full: {
    href: string;
    alt: string;
  };
} => {
  const mediaData = Object.keys(item?.product?.media);

  let media = {} as {
    standard: {
      href: string;
      alt: string;
    };
    full: {
      href: string;
      alt: string;
    };
  };
  mediaData.forEach((key) => {
    if (key === "standard") {
      media.standard = { href: item?.product?.media[key]?.[0], alt: "" };
    }
    if (key === "full") {
      media.full = { href: item?.product?.media[key]?.[0], alt: "" };
    }
  });
  return media;
};

export const generateCartItem = (item: CentraSelectionItem) => {
  const name = getSelectionItemName(item) ?? "";
  const uri = getSelectionItemUri(item) ?? "";
  const variant = getSelectionItemVariantName(item) ?? "";
  const size = getSelectionItemSize(item) ?? "";
  const id = getSelectionItemLine(item) ?? "";
  const price = getSelectionItemPriceEach(item);
  const discountedPrice = getSelectionItemPriceEach(item);
  const quantity = getSelectionItemQuantity(item) ?? 0;
  const media = getFirstImage(item);
  const line = item.line ?? "";
  return {
    name,
    uri,
    variant,
    size,
    id,
    price,
    quantity,
    media,
    discountedPrice,
    line,
  };
};

export type GeneratedCartItem = ReturnType<typeof generateCartItem>;

/**
 * Related Products is an array of related products with all variants denormalized.
 * Now we must group them by productSku and set relatedProducts to the array of variants
 */
export const createRelationsFromDisplays = (
  relatedProducts: CentraProduct[],
) => {
  const productsGroupedByRelation = relatedProducts.reduce((acc, product) => {
    const productSku = product.productSku;
    if (!acc.has(productSku)) {
      acc.set(productSku, {
        ...product,
        relatedProducts: [],
      });
    }

    (acc.get(productSku)?.relatedProducts as CentraProduct[])?.push({
      ...product,
      relation: "variant",
    });
    return acc;
  }, new Map<string, CentraProduct>());

  return Array.from(productsGroupedByRelation.values());
};

export const getStandardRelatedProducts = (item: CentraProduct) => {
  const standardRelatedProducts = (
    item.relatedProducts as CentraProduct[]
  ).filter((product) => product.relation === "standard");

  const productsGroupedByRelation = createRelationsFromDisplays(
    standardRelatedProducts,
  );

  const standardRelation = productsGroupedByRelation.map((product) =>
    formatProductCard(product),
  );

  return standardRelation;
};

export const getIsNew = (item: CentraProduct) => {
  if ("pr_new" in item) return item.pr_new as "1";
  else return;
};

import { dataAtom, dataComposer } from "@frend-digital/centra";

import type { CentraProduct } from "@/lib/centra/atomicSetup";
import { baseAtomics } from "@/lib/centra/atomicSetup";

import { getSwatch, getVariants } from "../../localAtomics";

const {
  getName,
  getSku,
  getCollectionName,
  getCanonicalCategory,
  getProductSizes,
  getDisplayPriceWithSale
} = baseAtomics;

const getProductDescription = (product: CentraProduct) => {
  const productName = dataAtom(getName, 2)(product);
  const productPrice = dataAtom(getDisplayPriceWithSale, 2)(product);
  const productSku = dataAtom(getSku, 2)(product);
  const productCannonicalCategory = dataAtom(getCanonicalCategory, 2)(product);
  const productSize = dataAtom(getProductSizes, 2)(product);
  const variants = dataAtom(getVariants, 2)(product);
  const swatch = dataAtom(getSwatch, 2)(product);

  const description = dataComposer({
    name: productName,
    price: productPrice,
    sku: productSku,
    cannonicalCategory: productCannonicalCategory,
    size: productSize,
    variants,
    swatch
  });

  return description;
};

export default getProductDescription;

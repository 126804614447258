import { CentraProduct, MediaKeys } from "../atomicSetup";

export type FormattedMedia = {
  href: string;
  alt: string;
  type?: "image";
  isPackshot?: boolean;
};

export const getMediaObjects = (
  product: { mediaObjects?: CentraProduct["mediaObjects"] },
  size: MediaKeys
) => {
  const mediaList: FormattedMedia[] = [];
  for (const media of product.mediaObjects ?? []) {
    const source = media.sources[size]?.at(0);
    if (!source) {
      continue;
    }

    const attributes = Array.isArray(media.attributes)
      ? {}
      : media.attributes ?? {};

    const mediaObject = {
      type: "image",
      href: source.url,
      alt: "", // TODO - need alt attribute from Centra
      isPackshot: attributes.img_config_packshot === "1"
    } satisfies FormattedMedia;

    mediaList.push(mediaObject);
  }

  return mediaList;
};

import env from "@/env.mjs";
import type {
  FieldOrFunction,
  ICentraFilterUri,
  IFilterGroup,
  ISortOrderValue
} from "@/lib/types/centra";

import type { CentraProduct } from "@/lib/centra/atomicSetup";
import type { ProductCard } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import getCategoryId from "./getCategoryId";
import type { IFormattedFilters } from "./types";
import type { FilterValue, RawFilterResponse } from "./useProductFilter";

export type GetProductsReturn = Awaited<ReturnType<typeof getProducts>>;

type IFilterBodyTypes =
  | boolean
  | number
  | string
  | undefined
  | string[]
  | ISortOrderValue[]
  | ICentraFilterUri;

interface IFilterBody {
  uri: ICentraFilterUri | undefined;
  relatedProducts: boolean;
  limit: number;
  skipFirst: number;
  search: string | undefined;
  sortOrder: ISortOrderValue[];
  [index: string]: IFilterBodyTypes;
}

export type GetProducts = <PC extends ProductCard, ExtraData>(
  {
    cardFormatter,
    category,
    filters,
    productIds,
    search,
    sortOrder,
    page,
    productsPerPage,
    categories,
    extraDataFetcher,
    customerToken
  }: // new
  {
    cardFormatter: (product: CentraProduct, data?: ExtraData) => PC;
    category: string[] | [];
    categories?: string[];
    filters?: IFormattedFilters;
    filterGroups?: IFilterGroup[];
    productIds?: string[] | undefined;
    search?: string;
    sortOrder?: ISortOrderValue[];
    page?: number;
    productsPerPage?: number;
    customerToken?: string;
    extraDataFetcher?: (productIds: string[]) => Promise<ExtraData>;
    signal?: AbortSignal;
  },
  serverToken?: string
) => Promise<{
  products: PC[];
  filter: any[];
  token: string;
  productCount: number;
  filterCategories: FilterValue[];
}>;

export type GetProductsParameters = Parameters<typeof getProducts>[0];

const formatFilterGroups = (
  filterGroups: IFilterGroup[]
): IFormattedFilters => {
  const group: IFormattedFilters = filterGroups.map((group) => ({
    name: group.name,
    fieldOrFunction: group.fieldOrFunction as FieldOrFunction,
    required: group.required ?? false,
    labelFieldOrFunction: group.labelFieldOrFunction,
    values: undefined,
    toggle: (_value: string) => {},
    add: (_value: string) => {},
    remove: (_value: string) => {},
    clear: () => {},
    isOpen: () => false,
    toggleOpen: () => {},
    isActive: (_value: string) => false
  }));
  return group;
};

export const getProducts: GetProducts = async (
  {
    cardFormatter,
    category,
    categories,
    filters = [],
    filterGroups,
    productIds,
    search = "",
    sortOrder = [],
    page = 0,
    productsPerPage = 8,
    extraDataFetcher,
    customerToken,
    signal
  },
  serverToken?: string
) => {
  const apiUrl = env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;

  if (filters.length === 0 || filters === undefined) {
    if (filterGroups) filters = formatFilterGroups(filterGroups);
  }

  const categoryUri =
    category?.length > 0
      ? search
        ? undefined
        : { uri: category.join("/"), for: ["category"] }
      : undefined;

  const filterBody: IFilterBody = {
    uri: categoryUri,
    products: productIds,
    relatedProducts: true,
    limit: productsPerPage,
    skipFirst: page * productsPerPage,
    search: search,
    sortOrder: sortOrder,
    categories: categories?.filter(Boolean),
    pricelist: serverToken ? "all" : undefined
  };

  if (filters) {
    filters.forEach((filter) => {
      if (!filter.values) {
        return false;
      }
      return typeof filter.fieldOrFunction === "string"
        ? (filterBody[filter.fieldOrFunction] = filter.values)
        : filter.fieldOrFunction !== undefined &&
            (filterBody[filter.fieldOrFunction([]).field] = filter.values);
    });
  }
  if (category[0] !== "all") {
    const catIds = await getCategoryId(category, customerToken);
    if (catIds) filterBody["categories"] = [catIds];
  }

  try {
    const headers = new Headers({
      "API-token": customerToken || "",
      "Content-Type": "application/json"
    });

    if (serverToken) {
      headers.delete("API-token");
      headers.set("Authorization", `Bearer ${serverToken}`);
    }

    // RawFilterResponse;
    const res = await fetch(`${apiUrl}/products`, {
      headers,
      method: "POST",
      body: JSON.stringify(filterBody),
      cache: "no-store",
      signal
    });

    if (!res.ok) throw new Error("Error fetching products");
    const resData: RawFilterResponse = await res.json();
    let products = resData.products;
    const extraData = extraDataFetcher
      ? await extraDataFetcher(products.map((product) => product.product))
      : undefined;

    const formattedProducts = products.map((product) => {
      return cardFormatter(product, extraData);
    });
    const filterFields = filters.map((filter) => filter.fieldOrFunction);
    const formattedFilters =
      filters.length > 0
        ? resData.filter.filter(
            (centraFilter) =>
              filterFields.findIndex((field: any) =>
                typeof field === "string"
                  ? field === centraFilter.field
                  : field(resData.filter).field === centraFilter.field
              ) !== -1
          )
        : [];

    const filterCategories = getFilterCategories(resData, category[0]);

    return {
      ...resData,
      products: formattedProducts,
      filter: formattedFilters,
      filterCategories
    };
  } catch (error) {
    throw error;
  }
};

/**
 * Gets all valid categories for the current category
 */
function getFilterCategories(data: RawFilterResponse, primaryUri?: string) {
  const categoriesFilter = data.filter.find(
    (filter) => filter.field === "categories"
  );

  if (!categoriesFilter) return [];

  const filteredCategories = categoriesFilter.values.filter((value) => {
    const hasProducts = value.count > 0;
    let isInPrimary = true;
    if (primaryUri) {
      isInPrimary = value.data.uri.includes(primaryUri);
    }

    const isMultiple = value.data.name.length > 1;

    return hasProducts && isInPrimary && isMultiple;
  });

  return filteredCategories;
}

import { dataAtom, dataComposer } from "@frend-digital/centra";

import type { CentraProduct } from "@/lib/centra/atomicSetup";
import { baseAtomics } from "@/lib/centra/atomicSetup";

import { getIsNew } from "../../localAtomics";

const { getName, getUri, getVariantName } = baseAtomics;

const getProductCore = (product: CentraProduct) => {
  const name = dataAtom(getName, 2)(product);
  const slug = dataAtom(getUri, 2)(product);
  const variantName = dataAtom(getVariantName, 2)(product);
  const isNew = dataAtom(getIsNew, 2)(product);

  const core = dataComposer({
    name,
    slug,
    variantName,
    isNew
  });

  return core;
};

export default getProductCore;
